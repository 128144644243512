import React, {useEffect, useState} from 'react';
import Image from 'react-bootstrap/Image'
import {storage} from '../config'

const ImgDetail = (props) => {
    const path = "/images"+props.location.pathname
    const ref = storage.ref(path)
    const [src, setSrc] = useState("")
    const [initialized, setInitialized] = useState(false)

    let fetchImage = async () => {
        const promise = ref.getDownloadURL()
        const lSrc = await promise
        setSrc(lSrc)
        setInitialized(true)
    }
    useEffect(()=> {
        if (props.location.state && props.location.state.src) {
            setSrc(props.location.state.src)
            setInitialized(true)
        } else {
            fetchImage()
        }
    }, [initialized])
    if (!initialized) {
        return <div>Loading...</div>
    } else {
        if (src) {
    return <div> <Image src={src} fluid/> </div>
        } else {
            return <div>Failed to load image</div>
        }
    }
};

export default ImgDetail;