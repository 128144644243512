import React, {useState, useCallback} from 'react';
import Gallery from 'react-photo-gallery';
// import Gallery from 'react-grid-gallery';
import {storage} from '../config';
import {useHistory} from 'react-router-dom';
import './ImgGallery'
import Lightbox from "../Lightbox/Lightbox";
import "../Lightbox/style.css";

const ImgGallery = (props) => {


    //firebase stuff
    const path = "/images"
    const ref = storage.ref(path)

    const history = useHistory();

    // const [items, setItems] = useState([]);
    // const [initialized, setInitialized] = useState(false);

    // let getPhotos = async () => {
    //     let listOfObj = await ref.listAll()
    //     let listOfPics = listOfObj.items;
    
    //     let promises = []
    //     let names = []
    //     let timestamps = []
        
    //     listOfPics.forEach((pic) => {
    //         timestamps.push(pic.getMetadata());
    //         names.push(pic.name)
    //         promises.push(pic.getDownloadURL());
    //     })
    //     let imgs = []
        
    //     let times = await Promise.all(timestamps)

    //     await Promise.all(promises).then((images)=> {
    //         // let imgObjects = images.map((plainImg) => ({'key': plainImg, 'src': plainImg, 'width': '1', 'height': '1'}));
    //         for (var i = 0; i<images.length; i++) {
    //             imgs.push({timestamp: times[i].timeCreated, name: names[i], url: images[i], src: images[i], width: 500, height: 500})
    //         }
    //         console.log(imgs)
    //         imgs.sort(compare);
    //         setItems(items.concat(imgs))
    //         setInitialized(true)
    //     })


    // }

    // function compare(img1, img2) {
    //     // console.log(img1.timeStamp)
    //     if (img1.timestamp > img2.timestamp) return -1
    //     if (img1.timestamp < img2.timestamp) return 1
    //     return 0
    // }

    // useEffect(() => {
    //     if (!initialized) {
    //         getPhotos();
    //     }
    // }, [initialized]
    // ) 
    
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    
    const cOpenLightbox = useCallback((event, { photo, index }) => {
        
    
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);


    
    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    
    const openInNewTab = ((cI, event) => {
        
        let current = props.items[cI]
        
        history.push('/'+current.name, {'src': current.src})
    })

    
    
return  <div >
    {/* <Gallery images={items} onClickThumbnail={cOpenLightbox} isOpen={false} currentImage={currentImage} rowHeight={400} enableImageSelection={false} /> */}
    
    {props.items.length > 0 ? <Gallery photos={props.items} onClick={cOpenLightbox} margin={10} direction={"column"} className="gallery"/> : <div>No images</div>}
     
    {viewerIsOpen ? (<Lightbox images={props.items} startIndex={currentImage} onClose={closeLightbox} onClick={openInNewTab} /> ): null }

    </div>
};

export default ImgGallery;
    