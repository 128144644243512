import React, {useState, useEffect} from 'react';
import {Link, Router, Route } from 'react-router-dom';
import { createBrowserHistory as createHistory } from 'history'
import './App.css';
import ImgGallery from './ImgGallery/ImgGallery';
import ImgDetail from './ImgDetail/ImgDetail';
import {storage} from './config';
import pencils from './pencils.png';

const history = createHistory();

function App() {

  const path = "/images"
  const ref = storage.ref(path)

  const [items, setItems] = useState([]);
  const [initialized, setInitialized] = useState(false);

  let getPhotos = async () => {
      let listOfObj = await ref.listAll()
      let listOfPics = listOfObj.items;
    
      let promises = []
      let names = []
      let timestamps = []
        
      listOfPics.forEach((pic) => {
          timestamps.push(pic.getMetadata());
          names.push(pic.name)
          promises.push(pic.getDownloadURL());
      })
      let imgs = []
        
      let times = await Promise.all(timestamps)

      await Promise.all(promises).then((images)=> {
          // let imgObjects = images.map((plainImg) => ({'key': plainImg, 'src': plainImg, 'width': '1', 'height': '1'}));
          for (var i = 0; i<images.length; i++) {
              imgs.push({timestamp: times[i].timeCreated, name: names[i], url: images[i], src: images[i], width: 500, height: 500})
          }
          imgs.sort(compare);
          setItems(items.concat(imgs))
          setInitialized(true)
      })


  }

  function compare(img1, img2) {
      // console.log(img1.timeStamp)
      if (img1.timestamp > img2.timestamp) return -1
      if (img1.timestamp < img2.timestamp) return 1
      return 0
  }

  useEffect(() => {
    document.title="AG Art Gallery";
      if (!initialized) {
          getPhotos();
      }
  }, [initialized]
  )

  return (
    <div className="App">
      <Router history={history}>
        {/* <Navbar style={navStyle} expand="lg" variant="dark">
        <Navbar.Brand href="/">AG Art</Navbar.Brand>
        </Navbar> */}

        <Link to="/" ><img src={pencils} alt={"Home"} height={"200px"} width={"100%"}/></Link>
        {!initialized ? <div>Loading...</div> : <Route exact={true} path="/" render={(props) => <ImgGallery items={items} {...props} />}/>}
        
        <Route exact={false} path="/:imgId" component={ImgDetail}/>
      </Router>
    </div>
  );
}

export default App;
